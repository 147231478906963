import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageComponent } from '@features/gallery/components/image/image.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  standalone: true,
  imports: [CommonModule, IonicModule, ImageComponent, RouterModule],
})
export class NavigationHeaderComponent {}
