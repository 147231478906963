<ion-list class="ion-no-padding" lines="full">
  <ion-menu-toggle auto-hide="true">
    <ion-item routerLink="/ferienlager" title="Ferienlagerübersicht">
      <ion-label>Ferienlagerübersicht</ion-label>
    </ion-item>

    <ion-item routerLink="/faqs_ferienlagerangebote" title="Häufig gestellte Fragen">
      <ion-label>Fragen & Informationen</ion-label>
    </ion-item>

    <ion-item routerLink="/wer-ist-kinder-ferienlager-com" title="Über uns">
      <ion-label>Über Uns</ion-label>
    </ion-item>

    <ion-item routerLink="/bilder" title="Bildergallerien">
      <ion-label> Bilder </ion-label>
    </ion-item>

    <ion-item routerLink="/aktuelles" title="Unser Blog">
      <ion-label>Aktuelles</ion-label>
    </ion-item>

    <ion-item routerLink="/betreuer-gesucht" title="Werden Sie Betreuer">
      <ion-label>Betreuerportal</ion-label>
    </ion-item>
  </ion-menu-toggle>
</ion-list>
<div class="ion-margin-horizontal ion-padding-top">
  <ion-list slot="content" lines="full">
    <ion-item lines="none">
      <ion-label>
        <a href="tel:03614230623" title="Telefonnummer">Tel: 0361 4230 623</a><br /><br />
        <a href="mailto:info&#64;kinder-ferienlager.com" title="E-Mail Adresse"
          >info&#64;kinder-ferienlager.com</a
        >
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <ion-card class="ion-margin-horizontal ion-margin-top">
          <ion-card-content class="ion-text-center">
            <app-img
              [image]="{
                cdnBasePath:
                  'https://imagedelivery.net/rNFpJfph1XeZpzBC6NTnIA/0ef780cd-2cfc-408d-c330-716951bd8300',
                alt: 'Das Kinder-Ferienlager.com Männchen',
              }"
              imageWidth="184"
              cdnImageWidth="184"
            ></app-img>
          </ion-card-content>
        </ion-card>
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <p class="ion-text-center ion-padding-bottom">
          <a routerLink="/impressum" title="Rechtliches">Impressum</a>&nbsp;&nbsp;&nbsp;
          <a routerLink="/datenschutz" title="Alles zum Thema Datenschutz">Datenschutz</a>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</div>
