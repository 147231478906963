<ion-header>
  <ion-toolbar color="primary" mode="md">
    <ion-buttons slot="start">
      <ion-button routerLink="/">
        <app-img
          [image]="{
            cdnBasePath:
              'https://imagedelivery.net/rNFpJfph1XeZpzBC6NTnIA/c455ce3c-a984-4f8b-a802-4eb033537f00',
            alt: 'Kinder-Ferienlager.com',
          }"
          imageHeight="32"
          cdnImageHeight="32"
        ></app-img>
      </ion-button>
      <ion-button class="menu-item ion-hide-xl-down" routerLink="/ferienlager">
        <ion-label> Ferienlagerübersicht </ion-label>
      </ion-button>

      <ion-button
        class="menu-item ion-hide-xl-down"
        routerLink="/faqs_ferienlagerangebote"
        title="Häufig gestellte Fragen"
      >
        <ion-label> Fragen & Informationen </ion-label>
      </ion-button>
      <ion-button
        class="menu-item ion-hide-xl-down"
        routerLink="/wer-ist-kinder-ferienlager-com"
        title="Über uns"
      >
        <ion-label> Über uns </ion-label>
      </ion-button>
      <ion-button class="menu-item ion-hide-xl-down" routerLink="/bilder" title="Bildergallerien">
        Bilder
      </ion-button>
      <ion-button class="menu-item ion-hide-xl-down" routerLink="/aktuelles" title="Unser Blog">
        Aktuelles
      </ion-button>
    </ion-buttons>
    <ng-content></ng-content>
    <ion-buttons slot="end">
      <ion-menu-button class="ion-hide-xl-up"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
