<ion-row class="ion-hide-xl-down">
  <ion-toolbar color="primary">
    <ion-label>
      <ion-grid class="ion-margin ion-padding">
        <ion-row>
          <ion-col>
            <ion-card class="ion-margin-horizontal ion-margin-top">
              <ion-card-content class="ion-text-center">
                <app-img
                  [image]="{
                    cdnBasePath:
                      'https://imagedelivery.net/rNFpJfph1XeZpzBC6NTnIA/0ef780cd-2cfc-408d-c330-716951bd8300',
                    alt: 'Das Kinder-Ferienlager.com Männchen',
                  }"
                  imageWidth="141"
                  cdnImageWidth="141"
                ></app-img>
              </ion-card-content>
            </ion-card>

            <ion-item lines="none" color="primary">
              <ion-label>
                <p class="ion-text-center">
                  © 2025
                  <a href="https://kinder-ferienlager.com" title="Copyright KidFl"
                    >Kinder-Ferienlager.com</a
                  >
                </p>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col class="ion-padding-horizontal ion-padding-vertical">
            <h4 class="ion-margin-top">Kontakt</h4>
            <address>
              <p>Leuchtturm Thüringen e.V.</p>
              <p>Bahnhofstraße 8</p>
              <p>99084 Erfurt</p>
              <p><a href="tel:03614230623">0361 4230 623</a></p>
              <p>
                <a href="mailto:info&#64;kinder-ferienlager.com">info&#64;kinder-ferienlager.com</a>
              </p>
            </address>
          </ion-col>
          <ion-col class="ion-padding-horizontal ion-padding-vertical">
            <h4 class="ion-margin-top">Angebote</h4>
            <p>
              <a routerLink="/ferienlager" title="Ferienlagerübersicht">Ferienlagerübersicht</a>
            </p>
            <p>
              <a routerLink="/ferienlager/ostern" title="öffne Ostern in der Einzelansicht"
                >Frühling</a
              >
            </p>
            <p>
              <a routerLink="/ferienlager/sommer" title="öffne Sommer in der Einzelansicht"
                >Sommer</a
              >
            </p>
            <p>
              <a
                routerLink="/ferienlager/herbstferienlager"
                title="öffne Herbst in der Einzelansicht"
                >Herbst</a
              >
            </p>
            <p>
              <a routerLink="/ferienlager/winter" title="öffne Winter in der Einzelansicht"
                >Winter</a
              >
            </p>
          </ion-col>
          <ion-col class="ion-padding-horizontal ion-padding-vertical">
            <h4 class="ion-margin-top">Informationen</h4>
            <p>
              <a routerLink="/wer-ist-kinder-ferienlager-com" title="Beschreibung des Anbieters"
                >Über uns</a
              >
            </p>
            <p>
              <a
                routerLink="/betreuer-gesucht"
                title="Bewerbe dich als Teamer bei kinder-ferienlager.com"
                >Betreuer gesucht</a
              >
            </p>
            <p><a routerLink="/impressum" title="Herausgeber">Impressum</a></p>
            <p>
              <a
                routerLink="/datenschutz"
                title="Angaben und Einstellungen zur Verarbeitung von Daten auf dieser Seite"
                >Datenschutz</a
              >
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-label>
  </ion-toolbar>
</ion-row>
